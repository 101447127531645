<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="logo"></div>
    <div class="contactBox">
      <div class="telegram" @click="toTg"></div>
      <div class="potato" @click="toPotato"></div>
      <div class="line"></div>
    </div>
    <div class="figure">
      <div class="figureBox">
        <div class="label"></div>
        <div class="qrcodeBox">
          <div class="qrBg">
            <img class="qrcodeImg" :src="qrImg" alt="" />
          </div>
          <div class="qrcodeTip"></div>
        </div>
        <div class="btnBox">
          <div class="btn">
            <div class="iphoneBtn"></div>
            <div class="androidBtn"></div>
          </div>
          <div class="btnTip"></div>
        </div>
      </div>
    </div>
    <div class="exhibitBox">
      <div class="title1"></div>
      <div class="title2"></div>
      <div class="videoBox">
        <swiper
          class="videoSwiper"
          id="swiperBox"
          :options="swiperOption"
          ref="mySwiper"
        >
          <swiper-slide class="swiper-slide">
            <img class="bgImg" src="@/assets/images/pc/video1.webp" alt="" />
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <img class="bgImg" src="@/assets/images/pc/video2.webp" alt="" />
          </swiper-slide>
        </swiper>
        <div class="prevBtn"></div>
        <div class="nextBtn"></div>
      </div>
      <div class="title3"></div>
    </div>
    <!-- <div class="figure"></div>

    <div class="downloadBox">
      <div class="textBox"></div>
      <div class="codeAndBtn">
        <div class="qrcodeBox">
          <div class="scanCode"></div>
          <img class="qrcodeImg" :src="qrImg" alt="" />
        </div>
        <div class="btnBox">
          <div class="iosBtn"></div>
          <div class="androidBtn"></div>
          <div class="tip"></div>
        </div>
      </div>
    </div>
    <div class="bottomTip"></div> -->
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  data() {
    return {
      swiperOption: {
        navigation: {
          //导航箭头
          nextEl: ".nextBtn",
          prevEl: ".prevBtn",
        },
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        autoplayDisableOnInteraction: false,
        // direction: "swiperOption",
        speed: 1500,
        // effect: "fade",
        // fadeEffect: {
        //   crossFade: true,
        // },
        loop: true, // 循环模式选项
      },
    };
  },
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  methods: {
    // prevBtn() {
    //   console.log(this.$refs.mySwiper.swiper);
    //   this.swiper.slidePrev();
    // },
    // nextBtn() {
    //   this.swiper.slideNext();
    // },
    toTg() {
      window.open("https://t.me/wylljlq");
    },
    toPotato() {
      window.open("https://ptd6.app/jjdfangsdfe");
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.webp") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo {
    position: fixed;
    top: 20px;
    left: 40px;
    height: 88px;
    width: 230px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
  }
  .contactBox {
    position: fixed;
    top: 38px;
    right: 172px;
    display: flex;
    align-items: center;
    justify-content: center;
    .telegram {
      height: 40px;
      width: 128px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 16px;
      cursor: pointer;
    }
    .potato {
      height: 40px;
      width: 148px;
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 17px;
      cursor: pointer;
    }
    .line {
      height: 8px;
      width: 370px;
      background: url("./../../../assets/images/pc/line.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .figure {
    position: fixed;
    left: 210px;
    top: 139px;
    width: 705px;
    height: 775px;
    background: url("../../../assets/images/pc/figure.webp") no-repeat;
    background-size: 100% 100%;
    .figureBox {
      width: 705px;
      height: 775px;
      position: relative;
      .label {
        position: absolute;
        top: 569px;
        left: -29px;
        width: 727px;
        height: 39px;
        background: url("../../../assets/images/pc/label.png") no-repeat;
        background-size: 100% 100%;
      }
      .qrcodeBox {
        position: absolute;
        top: 643px;
        left: 12px;
        // transform: translateX(-50%);

        // padding: 47px 35px 15px 24px;
        // box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .qrBg {
          width: 168px;
          height: 168px;
          // background: url("../../../assets/images/pc/qrcodeBox.png") no-repeat;
          // background-size: 100% 100%;
          // width: 148px;
          // height: 148px;
          background: #fff;
          .qrcodeImg {
            width: 100%;
            height: 100%;
          }
        }
        .qrcodeTip {
          margin-top: 6px;
          width: 168px;
          height: 19px;
          background: url("../../../assets/images/pc/qrcodeTip.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .btnBox {
        position: absolute;
        top: 643px;
        left: 223px;
        .btn {
          display: flex;
          align-items: center;
          .iphoneBtn {
            width: 210px;
            height: 46px;
            margin-right: 30px;
            background: url("../../../assets/images/pc/iphoneBtn.png") no-repeat;
            background-size: 100% 100%;
          }
          .androidBtn {
            width: 210px;
            height: 46px;
            background: url("../../../assets/images/pc/androidBtn.png")
              no-repeat;
            background-size: 100% 100%;
          }
        }
        .btnTip {
          width: 461px;
          height: 116px;
          background: url("../../../assets/images/pc/btnTip.png") no-repeat;
          background-size: 100% 100%;
          margin-top: 24px;
        }
      }
    }
    // position: relative;
  }
  .exhibitBox {
    position: fixed;
    top: 152px;
    right: 165px;
    // width: 807px;
    // height: 807px;
    .title1 {
      width: 328px;
      height: 28px;
      background: url("../../../assets/images/pc/title1.png") no-repeat;
      background-size: 100% 100%;
      margin-left: 256px;
      margin-bottom: 53px;
    }
    .title2 {
      width: 732px;
      height: 60px;
      background: url("../../../assets/images/pc/title2.png") no-repeat;
      background-size: 100% 100%;
      margin-left: 52px;
      margin-bottom: 34px;
    }
    .title3 {
      width: 418px;
      height: 82px;
      background: url("../../../assets/images/pc/title3.png") no-repeat;
      background-size: 100% 100%;
      margin-left: 173px;
    }
    .videoBox {
      width: 807px;
      height: 531px;
      margin-bottom: 11px;
      background: url("../../../assets/images/pc/videoBoxBg.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      .prevBtn {
        width: 28px;
        height: 44px;
        background: url("../../../assets/images/pc/previousBtn.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 232px;
        left: 104px;
      }
      .nextBtn {
        width: 28px;
        height: 44px;
        background: url("../../../assets/images/pc/nextBtn.png") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 232px;
        right: 70px;
      }
      .videoSwiper {
        width: 607px;
        height: 350px;
        position: absolute;
        top: 93px;
        left: 156px;
        .swiper-slide {
          width: 539px;
          height: 100%;
          padding: 0 5px;
          // margin: 0 10px;
          box-sizing: border-box;
        }
        .bgImg {
          width: 529px;
          height: 100%;
          object-fit: fill;
        }
      }
    }
  }
  // .content {
  //   position: fixed;
  //   left: 95px;
  //   top: 149px;
  //   display: flex;
  // }

  // .logoText {
  //   height: 319px;
  //   width: 821px;
  //   background: url("./../../../assets/images/pc/logoText.png") no-repeat;
  //   background-size: 100% 100%;
  //   position: absolute;
  //   left: 222px;
  //   top: 263px;
  // }

  // .downloadBox {
  //   position: absolute;
  //   right: 231px;
  //   top: 229px;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-end;
  //   .textBox {
  //     width: 610px;
  //     height: 321px;
  //     background: url("../../../assets/images/pc/text.png") no-repeat;
  //     background-size: 100% 100%;
  //     margin-bottom: 70px;
  //   }
  //   .codeAndBtn {
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //   }
  //   .qrcodeBox {
  //     height: 210px;
  //     width: 210px;
  //     border-radius: 18px;
  //     background-color: white;
  //     // border-radius: 10px;
  //     margin-right: 40px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     position: relative;
  //     .scanCode {
  //       position: absolute;
  //       top: -52px;
  //       left: -123px;
  //       width: 135px;
  //       height: 84px;
  //       background: url("../../../assets/images/pc/scanCode.png") no-repeat;
  //       background-size: 100% 100%;
  //     }
  //     .qrcodeImg {
  //       width: 190px;
  //       height: 190px;
  //     }
  //   }
  //   .btnBox {
  //     // margin-left: 40px;
  //     .iosBtn {
  //       width: 324px;
  //       height: 68px;
  //       margin-bottom: 21px;
  //       background: url("../../../assets/images/pc/iosBtn.png") no-repeat;
  //       background-size: 100% 100%;
  //     }
  //     .androidBtn {
  //       width: 324px;
  //       height: 68px;
  //       background: url("../../../assets/images/pc/androidBtn.png") no-repeat;
  //       background-size: 100% 100%;
  //       margin-bottom: 20px;
  //     }
  //     .tip {
  //       width: 251px;
  //       height: 30px;
  //       background: url("../../../assets/images/pc/ucTip.png") no-repeat;
  //       background-size: 100% 100%;
  //     }
  //   }
  //   // .tip {
  //   //   width: 486px;
  //   //   height: 250px;
  //   //   background: url("../../../assets/images/pc/tip.png") no-repeat;
  //   //   background-size: 100% 100%;
  //   // }
  // }
  // .bottomTip {
  //   width: 840px;
  //   height: 68px;
  //   background: url("../../../assets/images/pc/tip.png") no-repeat;
  //   background-size: 100% 100%;
  //   position: fixed;
  //   bottom: 64px;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
}
</style>
